import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { FrameContexts, IAppDefinition } from '@metaos/hub-sdk';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { DefaultApps, IAppDictionary } from '../App';
import { AppContainerRouteContext } from '../Contexts';
import { AppContainerWrapper } from './AppContainerWrapper';

const getAppDefinitionFromAppId = (
  appId: string,
  sideloadedApps: IAppDictionary,
  goToRoot: () => void
): IAppDefinition => {
  //check default apps first
  const appFromDefaults = DefaultApps[appId];
  if (appFromDefaults) {
    return appFromDefaults;
  }

  // check sideloaded next
  const appFromSideloaded = sideloadedApps[appId];
  if (appFromSideloaded) {
    return appFromSideloaded;
  }

  // push to the default location if not found
  goToRoot();
  return DefaultApps['com.example.metaostestapp.test']; // Test App
};

export interface AppContainerRouteProps {
  selectedAppId: string;
  selectedTabName?: string;
  frameContext: FrameContexts;
  showTestFrame: boolean;
  sideloadedApps: IAppDictionary;
  subEntityId?: string;
}

const getSelectedTab = (appDefinition: IAppDefinition, selectedTabName?: string): SelectedTabInfo => {
  let selectedTab = appDefinition.staticTabs?.[0];
  if (selectedTabName) {
    const tab = appDefinition.staticTabs?.find((tab) => tab.name === selectedTabName);
    if (tab) {
      selectedTab = tab;
    }
  }

  return { contentUrl: selectedTab?.contentUrl ?? '', entityId: selectedTab?.entityId ?? '' };
};

interface SelectedTabInfo {
  contentUrl: string;
  entityId: string;
}

export const AppContainerRoute: React.FC<AppContainerRouteProps> = (props: AppContainerRouteProps) => {
  const { selectedAppId, selectedTabName, frameContext, showTestFrame, sideloadedApps, subEntityId } = props;

  const userClickTime = new Date().getTime();
  const history = useHistory();

  const appDefinition = getAppDefinitionFromAppId(selectedAppId, sideloadedApps, () => history.push('/'));
  const selectedTab = getSelectedTab(appDefinition, selectedTabName);
  const [items, setItems] = React.useState<ICommandBarItemProps[]>([]);

  React.useEffect(() => {
    const tempItems: ICommandBarItemProps[] = [];
    appDefinition.staticTabs?.forEach((tab) => {
      tab.contentUrl &&
        tempItems.push({
          key: tab.name,
          text: tab.name,
          onClick: () => {
            history.push(`/app/${selectedAppId}/${tab.name}`);
          },
        });
    });
    setItems(tempItems);
  }, [appDefinition]);

  return (
    <AppContainerRouteContext.Provider value={{ appDefinition }}>
      {items.length > 1 && <CommandBar items={items} />}
      <AppContainerWrapper
        appName={selectedAppId}
        contentUrl={selectedTab.contentUrl}
        entityId={selectedTab.entityId}
        subEntityId={subEntityId}
        frameContext={frameContext}
        showTestFrame={showTestFrame}
        userClickTime={userClickTime}
      />
    </AppContainerRouteContext.Provider>
  );
};
