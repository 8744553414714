import { Toggle } from '@fluentui/react';
import * as React from 'react';

import { ICapabilitiesState } from '../App';

interface ICapabilityToggleProps {
  setCapabilities: React.Dispatch<React.SetStateAction<ICapabilitiesState>>;
  capabilities: ICapabilitiesState;
}

export const CapabilityToggle = ({ setCapabilities, capabilities }: ICapabilityToggleProps) => {
  const handleToggleChange = (moduleName: string) => {
    setCapabilities((prevState: ICapabilitiesState) => {
      return {
        ...prevState,
        [moduleName]: !prevState[moduleName],
      };
    });
  };
  return (
    <>
      <h2>Capabilities</h2>
      <Toggle
        label="Support Calendar"
        checked={capabilities.calendar}
        onChange={() => handleToggleChange('calendar')}
        id="calendarToggle"
      />
      <Toggle
        label="Support Location"
        checked={capabilities.location}
        onChange={() => handleToggleChange('location')}
        id="locationToggle"
      />
      <Toggle
        label="Support Mail"
        checked={capabilities.mail}
        onChange={() => handleToggleChange('mail')}
        id="mailToggle"
      />
      <Toggle
        label="Support Media"
        checked={capabilities.media}
        onChange={() => handleToggleChange('media')}
        id="mediaToggle"
      />
      <Toggle
        label="Support Notifications"
        checked={capabilities.notifications}
        onChange={() => handleToggleChange('notifications')}
        id="notificationsToggle"
      />
      <Toggle
        label="Support Page"
        checked={capabilities.page}
        onChange={() => handleToggleChange('page')}
        id="pageToggle"
      />
      <Toggle
        label="Support Page AppButton"
        disabled={!capabilities.page}
        checked={capabilities.pageAppButton}
        onChange={() => handleToggleChange('pageAppButton')}
        id="pageAppButtonToggle"
      />
      <Toggle
        label="Support Page Tabs"
        disabled={!capabilities.page}
        checked={capabilities.pageTabs}
        onChange={() => handleToggleChange('pageTabs')}
        id="pageTabsToggle"
      />
      <Toggle
        label="Support Page Config"
        disabled={!capabilities.page}
        checked={capabilities.pageConfig}
        onChange={() => handleToggleChange('pageConfig')}
        id="pageConfigToggle"
      />
      <Toggle
        label="Support Page FullTrust"
        checked={capabilities.pageFullTrust}
        onChange={() => handleToggleChange('pageFullTrust')}
        id="pageFullTrust"
      />
      <Toggle
        label="Support Meeting"
        checked={capabilities.meeting}
        onChange={() => handleToggleChange('meeting')}
        id="meetingToggle"
      />
      <Toggle
        label="Support MeetingRoom"
        checked={capabilities.meetingRoom}
        onChange={() => handleToggleChange('meetingRoom')}
        id="meetingRoomToggle"
      />
      <Toggle
        label="Support Dialog"
        checked={capabilities.dialog}
        onChange={() => handleToggleChange('dialog')}
        id="dialogToggle"
      />
      <Toggle
        label="Support Chat"
        checked={capabilities.chat}
        onChange={() => handleToggleChange('chat')}
        id="chatToggle"
      />
      <Toggle
        label="Support Teams"
        checked={capabilities.teams}
        onChange={() => handleToggleChange('teams')}
        id="teamsToggle"
      />
      <Toggle
        label="Support Teams Full Trust"
        checked={capabilities.teamsFullTrust}
        onChange={() => handleToggleChange('teamsFullTrust')}
        id="teamsFullTrust"
      />
      <Toggle
        label="Support Files"
        checked={capabilities.files}
        onChange={() => handleToggleChange('files')}
        id="filesToggle"
      />
      <Toggle
        label="Support People"
        checked={capabilities.people}
        onChange={() => handleToggleChange('people')}
        id="peopleToggle"
      />
      <Toggle
        label="Support RemoteCamera"
        checked={capabilities.remoteCamera}
        onChange={() => handleToggleChange('remoteCamera')}
        id="remoteCameraToggle"
      />
      <Toggle
        label="Support Monetization"
        checked={capabilities.monetization}
        onChange={() => handleToggleChange('monetization')}
        id="monetizationToggle"
      />
      <Toggle
        label="Support Call"
        checked={capabilities.call}
        onChange={() => handleToggleChange('call')}
        id="callToggle"
      />
      <Toggle
        label="Support AppInstallDialog"
        checked={capabilities.appInstallDialog}
        onChange={() => handleToggleChange('appInstallDialog')}
        id="appInstallDialogToggle"
      />
      <Toggle
        label="Support AppEntity"
        checked={capabilities.appEntity}
        onChange={() => handleToggleChange('appEntity')}
        id="appEntityToggle"
      />
      <Toggle
        label="Support Sharing"
        checked={capabilities.sharing}
        onChange={() => handleToggleChange('sharing')}
        id="sharingToggle"
      />
      <Toggle
        label="Support Bot"
        checked={capabilities.bot}
        onChange={() => handleToggleChange('bot')}
        id="botToggle"
      />
    </>
  );
};
