import {
    ConversationsServiceEventMap,
    EventService,
    IConversationsService,
    OpenConversationParams,
  } from '@metaos/hub-sdk';
  
  class ConversationsService extends EventService<ConversationsServiceEventMap> implements IConversationsService {
    public openConversation = (params: OpenConversationParams) => {
      alert(`openConversation called with ${JSON.stringify(params)}`);
      return Promise.resolve(true);
    };
    public closeConversation = () => {
      alert(`closeConversation called`);
      return Promise.resolve();
    };
    public getChatMembers = () => {
      alert(`getChatMembers called`);
      return Promise.resolve({ members: [{ upn: 'testUpn' }] });
    };
  }
  
  export const conversationsService = new ConversationsService();
  
  export const getConversationsService = (capabilities: any): IConversationsService | undefined => {
    if (capabilities.conversations) {
      return conversationsService;
    } else {
      return undefined;
    }
  };
  