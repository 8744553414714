import { Separator, Stack, StackItem } from '@fluentui/react';
import {
  FrameContexts,
  HostClientType,
  HubNames,
  IAppHostProps,
  IAuthenticationService,
  IDialogService,
  IMetaOsHubServices,
  MetaOsAppContainer,
  IAppDefinitionFull,
} from '@metaos/hub-sdk';
import { FailedReason } from '@metaos/hub-sdk/src/Services/constants';
import { AppHostWebview, WebviewCommunicationBridge } from '@metaos/hub-sdk-electron';
import * as React from 'react';

import { appEntityService } from '../../services/AppEntityService';
import { appInstallDialogService } from '../../services/AppInstallDialogService';
//import { botService } from '../../services/BotService';
import { calendarService } from '../../services/CalendarService';
import { callService } from '../../services/CallService';
import { getChatService } from '../../services/ChatService';
import { getDialogService } from '../../services/DialogService';
import { filesService } from '../../services/FilesService';
import { getLinkService } from '../../services/LinkService';
import { locationService } from '../../services/LocationService';
import { logService } from '../../services/LogService';
import { mailService } from '../../services/MailService';
import { mediaService } from '../../services/MediaService';
import { meetingRoomService } from '../../services/MeetingRoomService';
import { meetingService } from '../../services/MeetingService';
import { monetizationService } from '../../services/MonetizationService';
import { notificationService } from '../../services/NotificationService';
import { getPageService } from '../../services/PageService';
import { peopleService } from '../../services/PeopleService';
import { remoteCameraService } from '../../services/RemoteCameraService';
import { sharingService } from '../../services/SharingService';
import { getTeamsService } from '../../services/TeamsService';
import { TelemetryService } from '../../services/TelemetryService';
import { getHostClientType, getHostView } from '../../Utils';
import { ICapabilitiesState } from '../App';
import { AppContainerRouteContext, OrangeContext } from '../Contexts';
import { TestIframe } from '../Iframe/TestIFrame';
import { ShareDeepLinkDialog } from './ShareDeepLinkDialog';
import { TaskModuleDialog, TaskModuleDialogProps } from './TaskModuleDialog';
import { pwaService } from '../../pwa/PwaService';

var pjson = require('../../../package.json');

interface IMetaOsHubServiceOverride {
  dialogService?: IDialogService;
}

interface IAppContainerWrapperProps {
  appName: string;
  contentUrl: string;
  entityId: string;
  subEntityId?: string;
  frameContext: FrameContexts;
  showTestFrame: boolean;
  serviceOverrides?: IMetaOsHubServiceOverride;
  userClickTime: number;
}

const telemetryService = new TelemetryService();
const webviewCommunicationBridge = new WebviewCommunicationBridge();

const hubUiService = {
  getLoadingUi: () => <p id="hubLoadingUi">loading ...</p>,
  getErrorUi: (reason: FailedReason) => <p id="hubErrorUi">Error: {reason}</p>,
};

const getHubServices = (
  appName: string,
  entityId: string,
  authService: IAuthenticationService,
  capabilities: ICapabilitiesState,
  setTaskModuleDialogProps: (newProps: TaskModuleDialogProps) => void,
  setDeepLinkToShare: (urlToShare: string) => void,
  serviceOverrides: IMetaOsHubServiceOverride = {}
): IMetaOsHubServices => {
  const hubServices: IMetaOsHubServices = {
    appInstallDialogService: capabilities.appInstallDialog ? appInstallDialogService : undefined,
    authenticationService: authService,
    linkService: getLinkService(authService),
    //botService: capabilities.bot ? botService : undefined,
    calendarService: capabilities.calendar ? calendarService : undefined,
    callService: capabilities.call ? callService : undefined,
    dialogService: getDialogService(appName, setTaskModuleDialogProps, capabilities),
    hubUiService: hubUiService,
    locationService: capabilities.location ? locationService : undefined,
    logService: capabilities.log ? logService : undefined,
    mailService: capabilities.mail ? mailService : undefined,
    mediaService: capabilities.media ? mediaService : undefined,
    chatService: getChatService(capabilities),
    meetingService: capabilities.meeting ? meetingService : undefined,
    meetingRoomService: capabilities.meetingRoom ? meetingRoomService : undefined,
    peopleService: capabilities.people ? peopleService : undefined,
    remoteCameraService: capabilities.remoteCamera ? remoteCameraService : undefined,
    monetizationService: capabilities.monetization ? monetizationService : undefined,
    appEntityService: capabilities.appEntity ? appEntityService : undefined,
    notificationService: capabilities.notifications ? notificationService : undefined,
    pageService: getPageService(appName, entityId, setDeepLinkToShare, capabilities),
    sharingService: capabilities.sharing ? sharingService : undefined,
    teamsService: getTeamsService(capabilities),
    filesService: capabilities.files ? filesService : undefined,
    telemetryService,
  };

  return { ...hubServices, ...serviceOverrides };
};

export const AppContainerWrapper: React.FC<IAppContainerWrapperProps> = ({
  appName,
  contentUrl,
  entityId,
  frameContext,
  showTestFrame,
  serviceOverrides,
  subEntityId,
  userClickTime,
}) => {
  const { authService, capabilities, sessionId, fileOpenPreference, theme } = React.useContext(OrangeContext);
  const { appDefinition } = React.useContext(AppContainerRouteContext);
  const [hubService, setHubService] = React.useState<IMetaOsHubServices | undefined>(undefined);

  const [taskModuleDialogProps, setTaskModuleDialogProps] = React.useState<TaskModuleDialogProps>({
    appName,
    show: false,
  });
  const [deepLinkToShare, setDeepLinkToShare] = React.useState<string>();
  const shouldLoadWebview = getHostView() === 'webview';
  const createWebview = (): Promise<void> => {
    return Promise.resolve();
  };

  pwaService.loadPwaManifest(appDefinition as IAppDefinitionFull);

  const renderWebviewHost = (props: IAppHostProps) => {
    return <AppHostWebview {...props} createWebview={createWebview} />;
  };

  React.useEffect(() => {
    if (authService) {
      setHubService(
        getHubServices(
          appName,
          entityId,
          authService,
          capabilities,
          setTaskModuleDialogProps,
          setDeepLinkToShare,
          serviceOverrides
        )
      );
    }
  }, [authService]);

  if (!authService) {
    return <h1>Something went really wrong. Refresh?</h1>;
  }

  if (appDefinition === undefined) {
    return <h1> Invalid app path </h1>;
  }
  return (
    <>
      <Stack verticalFill>
        {hubService && (
          <StackItem grow>
            <MetaOsAppContainer
              // eslint-disable-next-line no-restricted-globals
              hostWindow={window}
              hubServices={hubService}
              appDefinition={appDefinition}
              contentUrl={contentUrl}
              context={{
                app: {
                  locale: 'en-us',
                  theme: theme,
                  userFileOpenPreference: fileOpenPreference,
                  sessionId: sessionId,
                  userClickTime: userClickTime,
                  host: {
                    name: HubNames.orange,
                    clientType: shouldLoadWebview ? HostClientType.desktop : getHostClientType(),
                    sessionId: 'dummySessionOrange',
                    version: pjson.version,
                  },
                },
                page: {
                  id: entityId,
                  subPageId: subEntityId,
                  frameContext: frameContext,
                },
              }}
              isolationProvider={
                shouldLoadWebview
                  ? {
                      communicationBridge: webviewCommunicationBridge,
                      renderHost: renderWebviewHost,
                    }
                  : undefined
              }
            />
          </StackItem>
        )}

        {showTestFrame && (
          <>
            <Separator />
            <StackItem grow>{<TestIframe url="" />}</StackItem>
          </>
        )}
      </Stack>
      <TaskModuleDialog {...taskModuleDialogProps} />
      <ShareDeepLinkDialog urlToShare={deepLinkToShare} onDismiss={() => setDeepLinkToShare(undefined)} />
    </>
  );
};
