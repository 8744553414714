import { FrameContexts, HostClientType } from '@metaos/hub-sdk';
import { v4 as uuid } from 'uuid';

export enum WebEnvironment {
  Test = 'test',
  Prod = 'prod',
}

export const getCurrentEnvironment = (): WebEnvironment => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('env')) {
    return urlParams.get('env')?.toLowerCase() as unknown as WebEnvironment;
  }
  return WebEnvironment.Prod;
};

export const getAppDefinitionOverrides = (): { [key: string]: string } => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('appDefOverrides')) {
    const overridesRaw = urlParams.get('appDefOverrides');
    if (overridesRaw) {
      const overrides = decodeURI(overridesRaw);
      return JSON.parse(overrides) as { [key: string]: string };
    }
  }
  return {};
};

export const generateSessionId = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('sessionId');
  return sessionId ? sessionId : uuid();
};

export const getFrameContext = (): FrameContexts => {
  const urlParams = new URLSearchParams(window.location.search);
  const frameContext = urlParams.get('frameContext');
  return frameContext ? (frameContext as FrameContexts) : FrameContexts.content;
};

export const getHostClientType = (): HostClientType => {
  const urlParams = new URLSearchParams(window.location.search);
  const hostClientType = urlParams.get('hostClientType');
  return hostClientType ? (hostClientType as HostClientType) : HostClientType.web;
};

export const getHostView = (): 'webview' | 'iframe' => {
  const urlParams = new URLSearchParams(window.location.search);
  const hostView = urlParams.get('hostView');
  return hostView && hostView === 'webview' ? 'webview' : 'iframe';
};
export const shouldUseCustomTelemetry = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  const useCustomTelemetry = urlParams.get('useCustomTelemetry');
  return useCustomTelemetry !== null;
};
