import { ILinkService, IAuthenticationService } from '@metaos/hub-sdk';
import { MetaOSServiceClient } from '../helpers/MetaOSAppServiceClient';
import { pwaService } from '../pwa/PwaService';
class LinkService implements ILinkService {

  private authService?: IAuthenticationService;
  private metaOSServiceClient: MetaOSServiceClient;

  constructor() {
    this.metaOSServiceClient = new MetaOSServiceClient();
  }

  public setAuthenticationSerivce(authService: IAuthenticationService) {
    this.authService = authService;
  }

  public openLink = async (url: string) => {

    let parsedUrl = new URL(url);
    let launchAppId = this.getAppIdToLaunch(parsedUrl);
    if (launchAppId && this.authService) {
      const token = await this.authService.acquireToken("https://teams.microsoft.com");   
      const result = await this.metaOSServiceClient.getEntitledApp(token.token!, launchAppId);
      if (result) {

        // Convert the manifest and add it to sideloads
        let appManifest = this.metaOSServiceClient.convertToHubSDKDefinition(result);
        let appId = appManifest.appId;

        // Add to the side loaded apps, if its not already there
        if (localStorage.getItem(appId) == null) {
          localStorage.setItem(
            'app.' + appId,
            JSON.stringify({
              content: appId,
              def: appManifest
            })
          );
        }

        var launchResult = false;
        //@ts-ignore
        const dredge = chrome?.webview?.hostObjects?.dredge;

        if (dredge) {
          let pwaManifest = pwaService.createPwaManifest(appManifest);

          //@ts-ignore
          launchResult = await dredge.AddMetaOSApp(JSON.stringify(pwaManifest));

          // Async bug with host objects. We optimistically ignore the result so we
          // don't open a second app window during the demo
          launchResult = true;
        }

        if (!launchResult) {
          console.log("Couldn't find Dredge, launching app in new window...")
          var currentUrl = new URL(document.location.href);
          var appUrl = currentUrl;
          appUrl.pathname = "/app/" + appManifest.appId;

          window.open(appUrl);
        }

      } else {
        console.error(`App definition for appid ${launchAppId} was not found in the entitlements returned`);
      }
    } else {
      console.log(`openLink called with ${url}`);
    }

    return Promise.resolve();
  };

  // Code to pull out the AppId from the Url
  private static readonly APP_URL_REGEX: RegExp = /^\/m365Apps\/([^\/]+)\/?$/;
  private getAppIdToLaunch = (url: URL) : string | undefined => {
    if (url.origin != 'https://teams.microsoft.com') {
      return undefined;
    }
    const matches = url.pathname.match(LinkService.APP_URL_REGEX);
    if (!matches) {
      return undefined;
    }
    return matches[1]
  }
}

export const linkService = new LinkService();

export const getLinkService = (
  authService: IAuthenticationService
): ILinkService => {
  linkService.setAuthenticationSerivce(authService);
  return linkService;
}

