import { IPeopleService, PeoplePickerInputs, PeoplePickerResult } from '@metaos/hub-sdk';

const tempPeoplePickerResult: PeoplePickerResult[] = [
  {
    objectId: '1',
    displayName: 'Name',
    email: 'test@microsoft.com',
  },
];

class PeopleService implements IPeopleService {
  public selectPeople(params: PeoplePickerInputs): Promise<PeoplePickerResult[]> {
    alert(`selectPeople called with ${JSON.stringify(params)}`);
    return Promise.resolve(tempPeoplePickerResult);
  }
}
export const peopleService = new PeopleService();
