import { Dropdown, IDropdownOption, PrimaryButton, Stack, TextField } from '@fluentui/react';
import * as React from 'react';

import { conversationsService } from '../../services/ConversationsService';
import { logService } from '../../services/LogService';
import { meetingRoomService } from '../../services/MeetingRoomService';
import { meetingService } from '../../services/MeetingService';
import { pageAppButtonService, pageBackStackService, pageConfigService, pageService } from '../../services/PageService';
import { remoteCameraService } from '../../services/RemoteCameraService';

export const CustomEventDispatcher = () => {
  const options: IDropdownOption[] = [
    { key: 'fullScreenChange', text: 'fullScreenChange' },
    { key: 'focusEnter', text: 'focusEnter' },
    { key: 'appButtonClick', text: 'appButtonClick' },
    { key: 'appButtonHoverEnter', text: 'appButtonHoverEnter' },
    { key: 'appButtonHoverLeave', text: 'appButtonHoverLeave' },
    { key: 'settings.save', text: 'settings.save' },
    { key: 'settings.remove', text: 'settings.remove' },
    { key: 'backButton', text: 'backButton' },
    { key: 'changeSettings', text: 'changeSettings' },
    { key: 'startConversation', text: 'startConversation' },
    { key: 'closeConversation', text: 'closeConversation' },
    { key: 'log.request', text: 'log.request' },
    { key: 'meetingRoomCapabilitiesUpdate', text: 'meetingRoomCapabilitiesUpdate' },
    { key: 'meetingRoomStatesUpdate', text: 'meetingRoomStatesUpdate' },
    { key: 'liveStreamChanged', text: 'meeting.liveStreamChanged' },
    { key: 'capableParticipantsChange', text: 'RCamera.capableParticipantsChange' },
    { key: 'handlerError', text: 'RCamera.handlerError' },
    { key: 'deviceStateChange', text: 'RCamera.deviceStateChange' },
    { key: 'sessionStatusChange', text: 'RCamera.sessionStatusChange' },
  ];
  const [input, setInput] = React.useState<string | undefined>('');

  const [selectedEventType, setSelectedEventType] = React.useState<string | undefined>();

  return (
    <>
      <Stack
        verticalFill
        id="eventRaiseComponent"
        tokens={{
          childrenGap: 10,
          padding: 10,
        }}
      >
        <Dropdown
          label="Event raiser component"
          options={options}
          placeholder="Select event type"
          onChange={(_, data: IDropdownOption) => {
            if (data) {
              const val = data as IDropdownOption;
              setSelectedEventType(val.text?.toString());
            }
          }}
          id="dropDown_eventRaise"
        />
        <TextField
          multiline
          rows={5}
          value={input}
          onChange={(_, data: string) => {
            setInput(data);
          }}
          placeholder="input event data"
          // styles={{ fieldGroup: { height: "100px" } }}
          id="text_eventRaise"
        />
        <PrimaryButton
          primary
          onClick={() => {
            RaiseEvent(selectedEventType, input);
          }}
          id="button_eventRaise"
        >
          Raise Event
        </PrimaryButton>
      </Stack>
    </>
  );
};

export const RaiseEvent = (eventType?: string, data?: any): void => {
  switch (eventType) {
    case 'fullScreenChange':
      pageService.dispatchEvent('fullScreenChange', { data });
      break;
    case 'focusEnter':
      pageService.dispatchEvent('focusEnter', { data });
      break;
    case 'appButtonClick':
      pageAppButtonService.dispatchEvent('click', {});
      break;
    case 'appButtonHoverEnter':
      pageAppButtonService.dispatchEvent('hoverEnter', {});
      break;
    case 'appButtonHoverLeave':
      pageAppButtonService.dispatchEvent('hoverLeave', {});
      break;
    case 'settings.save':
      pageConfigService.dispatchEvent('settings.save', data && { data: JSON.parse(data) });
      break;
    case 'settings.remove':
      pageConfigService.dispatchEvent('settings.remove', {});
      break;
    case 'changeSettings':
      pageConfigService.dispatchEvent('changeSettings', {});
      break;
    case 'backButton':
      pageBackStackService.dispatchEvent('backButton', {});
    case 'startConversation':
      conversationsService.dispatchEvent('startConversation', data && { data: JSON.parse(data) });
      break;
    case 'closeConversation':
      conversationsService.dispatchEvent('closeConversation', data && { data: JSON.parse(data) });
      break;
    case 'log.request':
      logService.dispatchEvent('log.request', {});
      break;
    case 'meetingRoomCapabilitiesUpdate':
      meetingRoomService.dispatchEvent('meetingRoomCapabilitiesUpdate', data && { data: JSON.parse(data) });
      break;
    case 'meetingRoomStatesUpdate':
      meetingRoomService.dispatchEvent('meetingRoomStatesUpdate', data && { data: JSON.parse(data) });
    case 'meeting.liveStreamChanged':
      meetingService.dispatchEvent('liveStreamChanged', data && { data: JSON.parse(data) });
      break;
    case 'RCamera.capableParticipantsChange':
      remoteCameraService.dispatchEvent('capableParticipantsChange', data && { data: [JSON.parse(data)] });
      break;
    case 'RCamera.handlerError':
      remoteCameraService.dispatchEvent('handlerError', data && { data: JSON.parse(data) });
      break;
    case 'RCamera.deviceStateChange':
      remoteCameraService.dispatchEvent('deviceStateChange', data && { data: JSON.parse(data) });
      break;
    case 'RCamera.sessionStatusChange':
      remoteCameraService.dispatchEvent('sessionStatusChange', data && { data: JSON.parse(data) });
      break;
  }
};
