import { EventService, ILogService, LogServiceEventMap } from '@metaos/hub-sdk';

class LogService extends EventService<LogServiceEventMap> implements ILogService {
  public handleAppLog(appLog: string): Promise<void> {
    alert(`handleAppLog called with appLog: ${appLog}`);
    return Promise.resolve();
  }
}

export const logService = new LogService();
