import { initializeIcons, Separator, Stack, StackItem, Toggle } from '@fluentui/react';
import { AppDefEnum, FileOpenPreference, getAppDefinition, Themes, IAppDefinitionFull, IAppDefinition, IAuthenticationService } from '@metaos/hub-sdk';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { SignIn } from './Header/SignIn';

//import { getMockAppDefinition } from '../mocks/MockAppDefinition';
import MockAuthenticationService from '../mocks/MockAuthenticationService';
import AuthenticationService, { IAuthenticationServiceExtended } from '../services/AuthenticationService';
import {
  generateSessionId,
  getAppDefinitionOverrides,
  getCurrentEnvironment,
  getFrameContext,
  WebEnvironment,
} from '../Utils';
import { OrangeContext } from './Contexts';
import { Header } from './Header';
import { LeftBar } from './LeftBar';
import { AppContainerRoute } from './Main/AppContainerRoute';
import { CapabilityToggle } from './Main/CapabilityToggle';

export const Store: IAppDefinitionFull = {
  accentColor: '#006600',
  appId: 'f3a6e67f-850d-4dd9-960a-04c6638ded36',
  developerName: 'Corentin',
  developerUrl: 'https://www.example.com',
  largeImageUrl: 'https://static2.sharepointonline.com/files/fabric-cdn-prod_20200430.002/assets/brand-icons/product/png/office_48x2.png',
  longDescription: 'Test Test Test Test',
  name: 'Office Store',
  staticTabs: [
    {
      contentUrl: 'https://teams.microsoft.com/extensibility-apps/store/view?language=en-us&host=metaos&metaoshost=office',
      websiteUrl: 'https://jaguarshark.azurewebsites.net',
      entityId: '1',
      name: 'Office Store',
    },
  ],
  validDomains: ['local.teams.office.com', 'teams.microsoft.com', 'jaguarshark.azurewebsites.net'],
  termsOfUseUrl: 'https://jaguarshark.azurewebsites.net',
  shortDescription: 'Office Store',
  showLoadingIndicator: true,
  isFullTrustApp: true,
  smallImageUrl: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/test_smallimage.png?v=0.1',
  webApplicationInfo: {
    id: 'f3a6e67f-850d-4dd9-960a-04c6638ded36',
    resource: 'https://teams.microsoft.com',
  },
  version: '0.0.0',

};

export const MOSA: IAppDefinitionFull = {
  appId: '9679c7fb-0c6e-4ae1-8313-2a9d6118381e',
  developerName: 'Neon',
  developerUrl: 'https://www.example.com',
  largeImageUrl: 'https://static2.sharepointonline.com/files/fabric-cdn-prod_20200430.002/assets/brand-icons/product/png/office_48x2.png',
  longDescription: 'MOSA Long Description ',
  name: 'MOSA',
  staticTabs: [
    {
      contentUrl: 'https://localhost:53000/index.html#/tab',
      websiteUrl: 'https://localhost:53000/index.html#/tab',
      entityId: '1',
      name: 'MOSA Personal Tab',
    }
  ],
  validDomains: ['local.teams.office.com', 'teams.microsoft.com', 'jaguarshark.azurewebsites.net', 'bing.com', 'contoso.com', 
  'localhost', 'localhost:53000'],
  termsOfUseUrl: 'https://localhost:53000/index.html#/termsofuse',
  shortDescription: 'MOSA Short Description',
  showLoadingIndicator: true,
  isFullTrustApp: false,
  webApplicationInfo: {
    id: '76eb2289-5278-4ffd-98e3-445c618a8029',
    resource: '',
  },
  version: '1.0.0',
}

// export const MOSADev: IAppManifest = {
//   appId: '0a7088d8-62e0-4617-95f3-4a444ece2be1',
//   developerName: 'Neon',
//   developerUrl: 'https://www.example.com',
//   largeImageUrl: 'https://static2.sharepointonline.com/files/fabric-cdn-prod_20200430.002/assets/brand-icons/product/png/office_48x2.png',
//   longDescription: 'MOSA Long Description ',
//   name: 'MOSA Dev',
//   staticTabs: [
//     {
//       contentUrl: 'https://mosadev5af338tab.z22.web.core.windows.net/index.html#/tab',
//       websiteUrl: 'https://mosadev5af338tab.z22.web.core.windows.net/index.html#/tab',
//       entityId: '1',
//       name: 'MOSA Personal Tab',
//     }
//   ],
//   validDomains: ['local.teams.office.com', 'teams.microsoft.com', 'jaguarshark.azurewebsites.net', 'bing.com', 'contoso.com', 
//   'localhost', 'localhost:53000', 'local.teams.office.com:8080', 'local.teams.office.com:53000', 'local.mosa.com', 'local.mosa.com:53000',
//   'mosadev5af338tab.z22.web.core.windows.net'],
//   termsOfUseUrl: 'https://mosadev5af338tab.z22.web.core.windows.net/index.html#/termsofuse',
//   shortDescription: 'MOSA Short Description',
//   showLoadingIndicator: true,
//   // isFullTrustApp: true,
//   webApplicationInfo: {
//     id: '0a7088d8-62e0-4617-95f3-4a444ece2be1',
//     // resource: '',
//   },
//   version: '1.0.0',
// }

const HomePage: React.FC<{ setAuthService: (authService: IAuthenticationService) => void }> = ({ setAuthService }) => {
  return <h1> Welcome to Carrot. Please select or add an app. <SignIn setAuthService={setAuthService} /></h1>;
};

export interface IAppDictionary {
  [appId: string]: IAppDefinition;
}

export interface ICapabilitiesState {
  [module: string]: boolean;
}

export const DefaultApps: IAppDictionary = {
  ['com.example.metaostestapp.test']: getAppDefinition(AppDefEnum.Test),
  ['f3a6e67f-850d-4dd9-960a-04c6638ded36']: Store,
  ['9679c7fb-0c6e-4ae1-8313-2a9d6118381e']: MOSA,
  // ['0a7088d8-62e0-4617-95f3-4a444ece2be1']: MOSADev,
  ['com.example.microsoftteamstabs.test']: getAppDefinition(AppDefEnum.TeamsTestTab),
  ['955070e9-99a6-4319-b8df-32adf59949aa']: getAppDefinition(AppDefEnum.Yammer),
  ['eab2d3ce-6d6a-4415-abc4-5f40a8317b1f']: getAppDefinition(AppDefEnum.Monday),
  ['23e1001e-d855-4ad0-9f2b-55bcc2c20fbd']: getAppDefinition(AppDefEnum.ToDo),
  ['9b049ed6-fc8b-40c7-815b-bf84e344d270']: getAppDefinition(AppDefEnum.OrgExplorer),
};

const loadSideloadedAppsFromLocalStorage = (): IAppDictionary => {
  const tempApps: IAppDictionary = {};
  Object.keys(localStorage).forEach((key: string) => {
    // check if key in local storage has the 'app.' prefix
    if (key.startsWith('app.')) {
      const storedData = localStorage.getItem(key);
      // retrieve appData based on key and add to items state
      if (typeof storedData === 'string') {
        const appData = JSON.parse(storedData);
        tempApps[appData.content] = appData.def;
      }
    }
  });
  return tempApps;
};

export const App: React.FC = () => {
  const [authService, setAuthService] = useState<IAuthenticationServiceExtended>();
  const [theme, setTheme] = useState(Themes.default);
  const [fileOpenPreference, setFileOpenPreference] = useState(FileOpenPreference.Web);
  const [showTestFrame, setShowTestFrame] = useState(false);

  const [capabilities, setCapabilities] = useState<ICapabilitiesState>({
    appEntity: true,
    appInstallDialog: true,
    bot: true,
    calendar: true,
    call: true,
    location: true,
    log: true,
    mail: true,
    media: true,
    chat: true,
    meeting: true,
    meetingRoom: true,
    monetization: true,
    notifications: true,
    page: true,
    pageAppButton: true,
    pageTabs: true,
    dialog: true,
    pageConfig: true,
    pageBackStack: true,
    pageFullTrust: true,
    people: true,
    remoteCamera: true,
    sharing: true,
    teamsFulltrust: true,
    teams: true,
    files: true,
  });
  const [sessionId] = React.useState(generateSessionId);
  const [frameContext] = React.useState(getFrameContext);

  const [sideloadedApps, setSideloadedApps] = useState<IAppDictionary>(loadSideloadedAppsFromLocalStorage);
  const [showChrome, setShowChrome] = React.useState(false);

  const toggleChrome = () => {
    setShowChrome(!showChrome);
  }

  // swap AuthService with a mocked one for Test environment
  useEffect(() => {
    const currentEnv = getCurrentEnvironment();
    if (currentEnv == WebEnvironment.Test) {
      console.info('Running in Test mode');
      setAuthService(new MockAuthenticationService());
      DefaultApps[AppDefEnum.Test] = {
        ...Store,
        ...MOSA,
        ...getAppDefinitionOverrides(),
      };
    }
  }, []);

  // initialize icons and initialize Auth
  useEffect(() => {
    initializeIcons();

    let location = new URL(window.location.href);
    if (location.pathname.startsWith("/app/")) {
      new AuthenticationService().init().then((k) => setAuthService(k));
    }

  }, []);

  return (
    <OrangeContext.Provider value={{ authService, capabilities, theme, fileOpenPreference, sessionId }}>
      <Router>
        <Stack className="App" verticalFill>
          <div style={{ backgroundColor: '#FF9D5C', height: '60px', position: 'fixed', right: 0, bottom: 0, opacity: 0.01 }}>
            <Toggle
              label="Show UI"
              id="showChromeToggle"
              offText="hidden"
              onText="visible"
              onChange={() => {
                toggleChrome();
              }}
            />
          </div>
          {showChrome &&
            <StackItem>
              <Header authService={authService} setAuthService={setAuthService} />
            </StackItem>
          }
          <StackItem grow>
            <Stack horizontal verticalFill grow>
              {showChrome &&
                <StackItem style={{ width: 180 }}>
                  <LeftBar
                    sideloadedApps={sideloadedApps}
                    handleSideloadApp={() => setSideloadedApps(loadSideloadedAppsFromLocalStorage)}
                    setTheme={setTheme}
                    setFileOpenPreference={setFileOpenPreference}
                    showTestFrame={showTestFrame}
                    setShowTestFrame={setShowTestFrame}
                  />
                </StackItem>
              }
              {showChrome &&
                <Separator vertical />
              }
              <StackItem grow>
                <Stack grow verticalFill className="main">
                  <Route exact path="/" render={() =>
                    authService ? (
                      <Redirect to="/app/f3a6e67f-850d-4dd9-960a-04c6638ded36"/>
                    ) : (
                      <HomePage setAuthService={setAuthService}/>
                    )
                  } />
                  <Route
                    path="/app/:appId/:tabName?/:subEntityId?"
                    render={({ match }) => {
                      return (
                        <>
                          {authService && (
                            <AppContainerRoute
                              selectedAppId={match.params.appId}
                              sideloadedApps={sideloadedApps}
                              selectedTabName={match.params.tabName}
                              subEntityId={match.params.subEntityId}
                              frameContext={frameContext}
                              showTestFrame={showTestFrame}
                            />
                          )}
                          {!authService && <h1>Please Sign In to launch the application <SignIn setAuthService={setAuthService} /></h1>}
                        </>
                      );
                    }}
                  />
                  <Route
                    path="/capability-settings"
                    render={() => <CapabilityToggle setCapabilities={setCapabilities} capabilities={capabilities} />}
                  />
                </Stack>
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </Router>
    </OrangeContext.Provider>
  );
};

export default App;
