import {
  BarCodeConfig,
  FileFormat,
  ImageUri,
  IMediaService,
  Media,
  MediaInputs,
  MediaResultCallback,
} from '@metaos/hub-sdk';

const reserveMedias: Media[] = [
  {
    content: 'ABCDEFGHIJKL',
    format: FileFormat.ID,
    mimeType: 'text/plain',
    preview: 'ABC',
    size: 2,
    name: 'TempFile1',
  },
  {
    content: 'ABCDEFGHIJKL2',
    format: FileFormat.Base64,
    mimeType: '.bin',
    preview: 'ABC',
    size: 2,
    name: 'TempFile2',
  },
];

class MediaService implements IMediaService {
  public selectMedia = (input: MediaInputs): Promise<Media[]> => {
    alert(`selectMedia called with ${JSON.stringify(input)}`);
    return Promise.resolve(reserveMedias);
  };

  public async getMedia(mediaUriId: string, mediaResultCallback: MediaResultCallback): Promise<void> {
    alert(`getMedia called with ${JSON.stringify(mediaUriId)}`);
    const response = await fetch('https://local.teams.office.com:8080/bob.jpg');
    const data = await response.blob();
    const fileSize = data.size;

    let current = 0;
    let i = 1;
    const chunkSize = 5000;

    while (current < fileSize) {
      const slice = data.slice(current, current + chunkSize);
      const sliceData = await slice.arrayBuffer();
      const sliceView = new Uint8Array(sliceData);
      const chars = String.fromCharCode(...Array.from(sliceView));
      const encoded = btoa(chars);

      const chunkData = {
        mediaChunk: {
          chunkSequence: i,
          chunk: encoded,
        },
      };
      mediaResultCallback(chunkData);
      i++;
      current += chunkSize;
    }

    mediaResultCallback({ mediaChunk: { chunk: '', chunkSequence: -1 } });

    return Promise.resolve();
  }

  public viewImages = (imageUriList: ImageUri[]): Promise<void> => {
    alert(`viewImages called with ${JSON.stringify(imageUriList)}`);
    return Promise.resolve();
  };

  public scanBarCode = (barCodeConfig: BarCodeConfig): Promise<string> => {
    alert(`scanBarCode called with ${JSON.stringify(barCodeConfig)}`);
    return Promise.resolve('decodedMessage');
  };
}
export const mediaService = new MediaService();
