import { IAuthenticationService, IAuthenticationToken, IAuthenticationUser } from '@metaos/hub-sdk';

export class MockAuthenticationService implements IAuthenticationService {
  public acquireToken(resource: string, claims?: string[], silent?: boolean): Promise<IAuthenticationToken> {
    // Calling alert only incase of auth service test where we specifically pass claims.
    // This avoids alert fiddling with preAuth calls for other tests
    if (claims && claims.length > 0) {
      alert(`acquireToken called with ${resource}, ${claims && claims.length > 0 ? claims[0] : ' '}, ${silent}`);
    }

    return Promise.resolve({ token: 'mockTokenFrom' + resource });
  }

  public getUser(): Promise<IAuthenticationUser | undefined> {
    return Promise.resolve({
      profile: {
        oid: 'mockoid',
        tid: 'mocktid',
        upn: 'mockupn',
        displayName: 'mockName',
      },
    });
  }

  public notifySuccess = async (data: string): Promise<void> => {
    alert(`AuthenticationService: Data: ${data}`);
  };

  public notifyFailure = async (reason: string): Promise<void> => {
    alert(`AuthenticationService: Reason: ${reason}`);
  };
}

export default MockAuthenticationService;
