import { ILocationService, Location, LocationProps } from '@metaos/hub-sdk';

class LocationService implements ILocationService {
  public getLocation(params: LocationProps): Promise<Location> {
    alert(`getLocation called with ${JSON.stringify(params)}`);
    const tempLocation: Location = {
      latitude: 51.507351,
      longitude: -0.127758,
      accuracy: 2,
      timestamp: 200,
    };
    return Promise.resolve(tempLocation);
  }
  public showLocation(params: Location): Promise<boolean> {
    alert(`showLocation called with ${JSON.stringify(params)}`);
    return Promise.resolve(true);
  }
}
export const locationService = new LocationService();
