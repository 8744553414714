import { PrimaryButton } from '@fluentui/react';
import { IAuthenticationService } from '@metaos/hub-sdk';
import * as React from 'react';

import AuthenticationService from '../../services/AuthenticationService';

interface ISignInProps {
  setAuthService: (authService: IAuthenticationService) => void;
}

export const SignIn = ({ setAuthService }: ISignInProps) => {
  const handleSignIn = () => {
    new AuthenticationService().init().then((k) => setAuthService(k));
  };
  return <PrimaryButton text="Sign In" onClick={handleSignIn} />;
};
