import { PrimaryButton } from '@fluentui/react';
import { IAuthenticationService } from '@metaos/hub-sdk';
import * as React from 'react';

import AuthenticationService, { IAuthenticationServiceExtended } from '../../services/AuthenticationService';

interface ISignOutProps {
  authService: IAuthenticationServiceExtended | undefined;
  setAuthService: (authService: IAuthenticationService) => void;
}

export const SignOut = ({ authService, setAuthService }: ISignOutProps) => {
  const handleSignOut = () => {

    // Signout and set the authservice to uninitialized so a login is forced
    authService && authService.signOut && authService.signOut().then(() => { setAuthService(new AuthenticationService) });
  };
  return <PrimaryButton text="Sign Out" onClick={handleSignOut} />;
};
