import { IAuthenticationService, IAuthenticationToken, IAuthenticationUser } from '@metaos/hub-sdk';
import * as Msal from '@azure/msal-browser';

let MSAL_CONFIG: Msal.Configuration = {
  auth: {
    // Teams Web
    //clientId: '5e3ce6c0-2b1f-4285-8d4b-75ee78787346', // Teams Web
    clientId: 'e52fb047-79fd-47dc-ae10-7d43e4741d39', // Kato / Neon 
    authority: 'https://login.microsoftonline.com/common',
    //navigateToLoginRequestUrl: false,
    redirectUri: 'https://local.teams.office.com:8080/',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export interface IAuthenticationServiceExtended extends IAuthenticationService {
  signOut?: () => Promise<void>;
}
export class AuthenticationService implements IAuthenticationServiceExtended {
  private myMSALObj: Msal.PublicClientApplication;
  private interactiveLoginRequest!: Msal.PopupRequest;
  private interactiveTokenRequest!: Msal.PopupRequest;
  private silentTokenRequest!: Msal.SilentRequest;

  constructor() {

    // Update MSAL config to whatever our base URL is
    var currentUrl = new URL(document.location.href);
    var baseUrl = currentUrl;
    baseUrl.pathname = "/";
    MSAL_CONFIG.auth.redirectUri = baseUrl.toString();

    this.myMSALObj = new Msal.PublicClientApplication(MSAL_CONFIG);

    // Register Callbacks for Redirect flow
    this.myMSALObj.handleRedirectPromise().then((tokenResponse) => {
      console.log(`MSAL redirect received with response: ${tokenResponse}`);
    }).catch((error) => {
      console.error(error);
    });

    this.setRequestObjects();
  }

  public init = async (): Promise<AuthenticationService> => {
    await this.login();
    return this;
  };

  public signOut = async (): Promise<void> => {
    await this.myMSALObj.logoutPopup();
  };

  public acquireToken = async (resource: string): Promise<IAuthenticationToken> => {
    const token = await this.getResourceToken(resource);
    return Promise.resolve({ token });
  };

  public getUser = async (): Promise<IAuthenticationUser> => {
    let account = this.getDefaultAccount();
    while (account == undefined) {
      await this.login();
      account = this.getDefaultAccount(); 
    }

    interface OurClaims {
      oid: any;
      tid: any;
      preferred_username: any;
    }
    const claims = account.idTokenClaims as OurClaims;

    return Promise.resolve({
      profile: {
        oid: claims.oid,
        tid: claims.tid,
        upn: claims.preferred_username
      },
    });
  };

  public notifySuccess = async (data: string): Promise<void> => {
    alert(`AuthenticationService: Data: ${data}`);
  };

  public notifyFailure = async (reason: string): Promise<void> => {
    alert(`AuthenticationService: Reason: ${reason}`);
  };

  /**
   * Initialize request objects used by this AuthModule.
   */
  private setRequestObjects(): void {
    this.interactiveLoginRequest = {
      scopes: [],
    };

    this.interactiveTokenRequest = {
      scopes: ['User.Read'],
    };

    this.silentTokenRequest = {
      scopes: ['openid', 'profile', 'User.Read'],
      account: undefined,
      forceRefresh: false,
    };
  }

  /**
   * Calls loginPopup.
   * @param signInType
   */
  login(): Promise<Msal.AuthenticationResult | undefined> {
    // If there is already an account dont force a loginPopup
    let account = this.getDefaultAccount();
    if (account) {
      return Promise.resolve(undefined);
    }

    return this.myMSALObj.loginPopup(this.interactiveLoginRequest);
  }

  private getDefaultAccount() : Msal.AccountInfo | undefined {
    let currentAccounts = this.myMSALObj.getAllAccounts();
    if (!currentAccounts || currentAccounts.length === 0) {
      return undefined;
    }

    return currentAccounts[0];
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive popup.
   */
  async getResourceToken(resource?: string): Promise<string | null> {
    this.silentTokenRequest.account = this.getDefaultAccount();

    if (resource) {
      this.silentTokenRequest.scopes = [resource + '/.default'];
      this.interactiveTokenRequest.scopes = [resource + '/.default'];
    }

    return this.getTokenPopup(this.silentTokenRequest, this.interactiveTokenRequest);
  }

  /**
   * Gets a token silently, or falls back to interactive popup.
   */
  private async getTokenPopup(
    silentRequest: Msal.SilentRequest,
    interactiveRequest: Msal.PopupRequest
  ): Promise<string | null> {
    try {
      const response: Msal.AuthenticationResult = await this.myMSALObj.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (e) {
      console.log('silent token acquisition fails.');
      if (e instanceof Msal.InteractionRequiredAuthError) {
        console.log('acquiring token using redirect');
        return this.myMSALObj
          .acquireTokenPopup(interactiveRequest)
          .then((resp) => {
            return resp.accessToken;
          })
          .catch((err) => {
            console.error(err);
            return null;
          });
      } else {
        console.error(e);
      }
    }
    return null;
  }
}

export default AuthenticationService;
