import { PwaManifest, PwaManifestIcon, PwaManifestServiceWorker } from './PwaManifest';
import { IAppDefinitionFull } from '@metaos/hub-sdk';

class PwaService {
    private baseUrl = new URL(document.location.href);
    private swPath = new URL('/pwa/service-worker.js', this.baseUrl);

    public createPwaManifest(mosApp: IAppDefinitionFull): PwaManifest {
        const scope = new URL('/app/' + mosApp.appId, this.baseUrl).toString();

        const icon: PwaManifestIcon = this.getIcon(mosApp);
        const serviceworker: PwaManifestServiceWorker = {
            src: this.swPath.toString(),
            scope: scope
        };
        const manifest: PwaManifest = {
            lang: 'en-us',
            id: mosApp.appId,
            name: mosApp.name,
            short_name: mosApp.name,
            description: mosApp.shortDescription ?? '',
            start_url: scope,
            scope: scope,
            background_color: '#2f3d58',
            theme_color: '#2f3d58',
            orientation: 'any',
            display: 'standalone',
            serviceworker: serviceworker,
            icons: [
                icon
            ]
        };
        return manifest;
    }

    public loadPwaManifest(mosApp: IAppDefinitionFull) {
        this.replaceTitle(mosApp.name);

        this.clearExistingManifest();

        const manifest: PwaManifest = this.createPwaManifest(mosApp);
        let content = encodeURIComponent(JSON.stringify(manifest));
        let url = "data:application/manifest+json," + content;
        let element = document.createElement('link');
        element.setAttribute('rel', 'manifest');
        element.setAttribute('href', url);
        document.querySelector('head')?.appendChild(element);

        const options = { scope: manifest.scope };

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(this.swPath, options).then(function(reg) {
                console.log('Successfully registered service worker. Scope: ' + reg.scope);

            }).catch(function(err) {
                console.log('Error whilst registering service worker' + err);
            });
        }
    }

    private getIcon(mosApp: IAppDefinitionFull): PwaManifestIcon {
        if (mosApp.appId == 'f3a6e67f-850d-4dd9-960a-04c6638ded36') {
            return {
                src: new URL('/images/store192.png', this.baseUrl).toString(),
                sizes: '192x192'
            };
        }

        if (mosApp.appId == '23e1001e-d855-4ad0-9f2b-55bcc2c20fbd') {
            return {
                src: new URL('/images/todo192.png', this.baseUrl).toString(),
                sizes: '192x192'
            };
        }

        return {
            src: mosApp.largeImageUrl ?? new URL('/images/icon192.png', this.baseUrl).toString(),
            sizes: '192x192'
        };
    }

    //private getImageSize(_imgUrl: string): string {
        // var blob = null;
        // var xhr = new XMLHttpRequest(); 
        // xhr.open('GET', imgUrl, true); 
        // xhr.responseType = 'blob';
        // xhr.onload = function() 
        // {
        //     blob = xhr.response;
        //     console.log(blob, blob.size);
        // }
        // xhr.send();

        //var size = performance.getEntriesByName(imgUrl)[0];
        //console.log('image data: ' + size);
        //return '192x192';
    //}

    private clearExistingManifest() {
        document.querySelector('head')?.querySelector('[rel=manifest]')?.remove();
    }

    private replaceTitle(newTitle: string) {
        this.clearExistingTitle();

        let element = document.createElement('title');
        element.text = newTitle;
        document.querySelector('head')?.appendChild(element);
    }

    private clearExistingTitle() {
        document.querySelector('head')?.querySelector('title')?.remove();
    }
};

export const pwaService = new PwaService();
