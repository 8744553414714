import { EventService, IMeetingRoomService, MeetingRoomInfo, MeetingRoomServiceEventMap } from '@metaos/hub-sdk';

const tempMeetingRoomInfo: MeetingRoomInfo = {
  endpointId: 'someEndpointId',
  deviceName: 'someDeviceName',
  clientType: 'clientType',
  clientVersion: 'clientVersion',
};

class MeetingRoomService extends EventService<MeetingRoomServiceEventMap> implements IMeetingRoomService {
  public getPairedMeetingRoomInfo(): Promise<MeetingRoomInfo> {
    alert(`getPairedMeetingRoomInfo is called`);
    return Promise.resolve(tempMeetingRoomInfo);
  }
  public sendCommandToPairedMeetingRoom(commandName: string): Promise<void> {
    alert(`sendCommandToPairedMeetingRoom is called with ${JSON.stringify(commandName)}`);
    return Promise.resolve();
  }
}
export const meetingRoomService = new MeetingRoomService();
