import { getTheme, IButtonStyles, IconButton, IIconProps, Modal, Stack, StackItem, Text } from '@fluentui/react';
import {
  DialogDimension,
  DialogServiceEventMap,
  DialogWithUrl,
  EventService,
  FrameContexts,
  IDialogService,
} from '@metaos/hub-sdk';
import React from 'react';

import { AppContainerWrapper } from './AppContainerWrapper';

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const theme = getTheme();

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const getDialogHeight = (height: DialogDimension | number = DialogDimension.Medium): number => {
  if (typeof height === 'number') return height;

  const windowHeight = window.innerHeight;
  switch (height) {
    case DialogDimension.Small:
      return windowHeight * 0.2;
    case DialogDimension.Medium:
      return windowHeight * 0.5;
    case DialogDimension.Large:
      return windowHeight * 0.66;
  }
};

const getDialogWidth = (width: DialogDimension | number = DialogDimension.Medium): number => {
  if (typeof width === 'number') return width;

  const windowWidth = window.innerWidth;
  switch (width) {
    case DialogDimension.Small:
      return windowWidth * 0.2;
    case DialogDimension.Medium:
      return windowWidth * 0.5;
    case DialogDimension.Large:
      return windowWidth * 0.6;
  }
};

class TaskModuleDialogService extends EventService<DialogServiceEventMap> implements IDialogService {
  constructor(
    private onSubmit: (result: string) => void,
    private onMessageForParent: (message: unknown) => Promise<void>
  ) {
    super();
  }

  messageForParent = (message: unknown): Promise<void> => {
    return this.onMessageForParent(message);
  };

  messageForChild(_: unknown): Promise<void> {
    return Promise.reject();
  }

  open(): Promise<string> {
    return Promise.reject();
  }

  resize(): Promise<void> {
    return Promise.reject();
  }

  submit(result?: string | object): Promise<void> {
    this.onSubmit(result as string);
    return Promise.resolve();
  }
}

export interface TaskModuleDialogProps {
  appName: string;
  show: boolean;
  dialogInfo?: DialogWithUrl;
  onDismiss?: () => void;
  onSubmit?: (result: string) => void;
  onMessageForParent?: (message: unknown) => Promise<void>;
}

export var taskModuleDialogServiceInstance: TaskModuleDialogService | undefined;

export const TaskModuleDialog: React.FC<TaskModuleDialogProps> = (props: TaskModuleDialogProps) => {
  const { appName, show, dialogInfo, onDismiss, onSubmit, onMessageForParent } = props;

  return (
    <Modal isOpen={show && !!dialogInfo} isBlocking>
      {dialogInfo && (
        <Stack tokens={{ childrenGap: 's1', padding: 's1' }} id="taskModuleDialogContainer">
          <StackItem styles={{ root: { borderBottom: '1px gray solid' } }}>
            <Stack horizontal>
              <StackItem grow>{dialogInfo.title && <Text variant="xxLarge">{dialogInfo.title}</Text>}</StackItem>
              <StackItem>
                <IconButton
                  styles={iconButtonStyles}
                  iconProps={cancelIcon}
                  ariaLabel="Close the Dialog"
                  onClick={onDismiss}
                  id="taskModuleDialogCloseButton"
                />
              </StackItem>
            </Stack>
          </StackItem>
          <StackItem
            styles={{
              root: {
                width: getDialogWidth(dialogInfo.width),
                height: getDialogHeight(dialogInfo.height),
              },
            }}
          >
            {dialogInfo.url && (
              <AppContainerWrapper
                appName={appName}
                contentUrl={dialogInfo.url}
                entityId={''}
                frameContext={FrameContexts.task}
                showTestFrame={false}
                serviceOverrides={{
                  dialogService: (() => {
                    var taskModuleDialogService = new TaskModuleDialogService(onSubmit!, onMessageForParent!);
                    taskModuleDialogServiceInstance = taskModuleDialogService;
                    return taskModuleDialogService;
                  })(),
                }}
                userClickTime={Date.now()}
              />
            )}
          </StackItem>
        </Stack>
      )}
    </Modal>
  );
};
