import {
  CloudStorageFolder,
  CloudStorageFolderItem,
  CloudStorageProvider,
  CloudStorageProviderType,
  ExternalProvider,
  FilePreviewParameters,
  ICopyMoveFilesParams,
  IDeleteCloudStorageFolderParams,
  IFilesService,
  IGetCloudStorageFolderContents,
  IOpenCloudStorageFileParams,
} from '@metaos/hub-sdk';

class FilesService implements IFilesService {
  public getCloudStorageFolderContents = (params: IGetCloudStorageFolderContents) => {
    alert(`getCloudStorageFolderContents called with ${JSON.stringify(params)}`);
    const cloudStorageFolderItems: CloudStorageFolderItem[] = [
      {
        id: 'test',
        isSubdirectory: false,
        lastModifiedTime: '23/06',
        type: 'ext',
        size: 50,
        objectUrl: 'object',
        title: 'testtitle',
      },
    ];
    return Promise.resolve(cloudStorageFolderItems);
  };
  public openFilePreview = (params: FilePreviewParameters) => {
    alert(`openFilePreview called with ${JSON.stringify(params)}`);
    return Promise.resolve();
  };
  public getCloudStorageFolders = (channelId: string) => {
    const folders: CloudStorageFolder[] = [
      {
        id: 'testId',
        title: 'testTitle',
        folderId: 'testFolderId',
        providerType: CloudStorageProviderType.Google,
        providerCode: CloudStorageProvider.GoogleDrive,
        ownerDisplayName: 'testOwnerDisplayName',
      },
    ];
    alert(`getCloudStorageFolders called with channelId: ${channelId}`);
    return Promise.resolve(folders);
  };
  public addCloudStorageFolder = (channelId: string) => {
    const folders: CloudStorageFolder[] = [
      {
        id: 'testId',
        title: 'testTitle',
        folderId: 'testFolderId',
        providerType: CloudStorageProviderType.Google,
        providerCode: CloudStorageProvider.GoogleDrive,
        ownerDisplayName: 'testOwnerDisplayName',
      },
    ];
    alert(`addCloudStorageFolder called with channelId: ${channelId}`);
    return Promise.resolve({ isFolderAdded: true, folders });
  };
  public deleteCloudStorageFolder = (params: IDeleteCloudStorageFolderParams) => {
    alert(`deleteCloudStorageFolder called with ${JSON.stringify(params)}`);
    return Promise.resolve(true);
  };
  public copyMoveFiles = (params: ICopyMoveFilesParams) => {
    alert(`copyMoveFiles called with ${JSON.stringify(params)}`);
    return Promise.resolve();
  };
  public getExternalProviders = (excludeAddedProviders?: boolean) => {
    alert(`getExternalProviders called with ${JSON.stringify(excludeAddedProviders)}`);
    const externalProviders: ExternalProvider[] = [
      {
        name: 'firstProvider',
        description: 'dDesc',
        thumbnails: [],
        providerCode: CloudStorageProvider.GoogleDrive,
        providerType: CloudStorageProviderType.Google,
      },
    ];
    return Promise.resolve(externalProviders);
  };

  public openCloudStorageFile(params: IOpenCloudStorageFileParams): Promise<void> {
    alert(`openCloudStorageFile called with ${JSON.stringify(params)}`);
    return Promise.resolve();
  }
}
export const filesService = new FilesService();
