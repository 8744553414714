import { AppEntity, IAppEntityService } from '@metaos/hub-sdk';

const selectAppEntityResult: AppEntity = {
  appId: '007',
  appIconUrl: 'appIncon_pengiun.com',
  contentUrl: 'contentUrl.com',
  displayName: 'penguin',
  websiteUrl: 'penguin.com',
};

class AppEntityService implements IAppEntityService {
  public selectAppEntity(threadId: string, categories: string[]): Promise<AppEntity> {
    alert(`appEntity.selectAppEntity called with ${threadId} + ${categories}`);
    return Promise.resolve(selectAppEntityResult);
  }
}
export const appEntityService = new AppEntityService();
