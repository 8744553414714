import * as React from 'react';

const baseStyles = (): React.CSSProperties => ({
  height: '100%',
  width: '100%',
  border: 0,
  flex: 1,
});

interface TestIframeProps {
  url: string;
}
export const TestIframe = ({ url }: TestIframeProps) => {
  const sandBoxProperties =
    'allow-forms allow-popups allow-popups-to-escape-sandbox allow-pointer-lock allow-scripts allow-same-origin allow-modals';
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
  const [srcUrl, setSrcUrl] = React.useState<string>(url);
  React.useEffect(() => {
    // @ts-ignore
    window.TestIframe = iframeRef.current?.contentWindow;
  }, [iframeRef]);

  React.useEffect(() => {
    // @ts-ignore
    // window.TestIframe = iframeRef.current?.contentWindow;
    setSrcUrl(window.TestUrl);
    // @ts-ignore
  }, [window.TestUrl]);
  return (
    <>
      <iframe
        id="test_iframe"
        data-tid="app-test-iframe"
        ref={iframeRef}
        src={srcUrl}
        style={{ ...baseStyles() }}
        sandbox={sandBoxProperties}
        title="WindowFrame"
      />
    </>
  );
};
