import {
  EventService,
  IAppContentStageSharingCapabilities,
  IAppContentStageSharingState,
  IMeetingDetails,
  IMeetingService,
  LiveStreamState,
  MeetingServiceEventMap,
  MeetingType,
} from '@metaos/hub-sdk';

class MeetingService extends EventService<MeetingServiceEventMap> implements IMeetingService {
  public getIncomingClientAudioState(): Promise<boolean | null> {
    alert('getIncomingClientAudioState called');
    return Promise.resolve(true);
  }
  public toggleIncomingClientAudio(): Promise<boolean | null> {
    alert('toggleIncomingClientAudio called');
    return Promise.resolve(false);
  }
  public getMeetingDetails(): Promise<IMeetingDetails | null> {
    alert('getMeetingDetails called');
    return Promise.resolve({
      details: {
        id: 'testDetailsId',
        scheduledStartTime: 'testStartTime',
        scheduledEndTime: 'testEndTime',
        joinUrl: 'testJoinUrl',
        title: 'testTitle',
        type: MeetingType.Unknown,
      },
      conversation: {
        id: 'testConversationId',
      },
      organizer: {
        id: 'testOrganizerId',
        tenantId: 'testTenantId',
      },
    });
  }
  public getAuthenticationTokenForAnonymousUser(): Promise<string | null> {
    alert('getAuthenticationTokenForAnonymousUser called');
    return Promise.resolve('testAuthToken');
  }

  public getLiveStreamState(): Promise<LiveStreamState> {
    alert('getLiveStreamState called');
    return Promise.resolve({
      isStreaming: true,
    });
  }
  public requestStartLiveStreaming(streamUrl: string, streamKey?: string): Promise<void> {
    alert(`requestStartLiveStreaming called with ${streamUrl} + ${streamKey}`);
    return Promise.resolve();
  }
  public requestStopLiveStreaming(): Promise<void> {
    alert('requestStopLiveStreaming called');
    return Promise.resolve();
  }
  public shareAppContentToStage(appContentUrl: string): Promise<boolean> {
    alert(`shareAppContentToStage called with ${appContentUrl}`);
    return Promise.resolve(true);
  }
  public getAppContentStageSharingCapabilities(): Promise<IAppContentStageSharingCapabilities> {
    alert('getAppContentStageSharingCapabilities called');
    return Promise.resolve({
      doesAppHaveSharePermission: true,
    });
  }
  public stopSharingAppContentToStage(): Promise<boolean> {
    alert('stopSharingAppContentToStage called');
    return Promise.resolve(true);
  }
  public getAppContentStageSharingState(): Promise<IAppContentStageSharingState> {
    alert('getAppContentStageSharingState called');
    return Promise.resolve({
      isAppSharing: true,
    });
  }
}
export const meetingService = new MeetingService();
