import { IChatService, OpenChatParams } from '@metaos/hub-sdk';

class ChatService implements IChatService {
  public openChat = (params: OpenChatParams) => {
    alert(`openChat called with ${JSON.stringify(params)}`);
    return Promise.resolve();
  };
}

const chatService = new ChatService();

export const getChatService = (capabilities: any): IChatService | undefined => {
  if (capabilities.chat) {
    return chatService;
  } else {
    return undefined;
  }
};
