import { DefaultButton, Dialog, DialogFooter, PrimaryButton, TextField } from '@fluentui/react';
import { IAppDefinition } from '@metaos/hub-sdk';
import { IStaticTab } from '@metaos/hub-sdk/src/Services/hosted-web-content-sdk.interface';
// import { IAppDefinition } from "@metaos/hub-sdk";
import * as React from 'react';

interface IAddAppProps {
  handleAddApp: () => void;
}

/**
 * Checks whether obj has appId and showLoadingIndicator properties
 * as well as at least one static tab defined
 * @param obj
 */
const isAppDef = (obj: any): obj is IAppDefinition => {
  return obj.appId !== undefined && obj.showLoadingIndicator !== undefined && hasAtLeastOneStaticTab(obj);
};

const hasAtLeastOneStaticTab = (obj: any): boolean => {
  const staticTabs = obj.staticTabs;
  return staticTabs && Array.isArray(staticTabs) && staticTabs.length > 0 && !staticTabs.find((st) => !isStaticTab(st));
};

const isStaticTab = (obj: any): obj is IStaticTab => {
  return obj.name && obj.contentUrl && obj.entityId;
};

/**
 * validates whether input is valid JSON and valid app definition
 * @param json
 */
const validateAppDef = (input: string): IAppDefinition | undefined => {
  try {
    const app = JSON.parse(input);
    if (isAppDef(app)) {
      return app;
    } else {
      alert(
        'Invalid App Definition: ensure appId and showLoadingIndicator properties are present' +
          'and at least one static tab is defined in the app definition.'
      );
      return undefined;
    }
  } catch (e) {
    alert('JSON Parse Failed: Invalid JSON input');
    return undefined;
  }
};

const validateAppName = (input: string): boolean => {
  if (!input) {
    alert('No App Name specified: Please name your app');
    return false;
  }

  if (!input.match(/^[a-zA-Z0-9 ]+$/g)) {
    alert('App Name can only consist alphanumeric characters');
    return false;
  }

  if (localStorage.getItem(input) !== null) {
    alert('App Name already taken: Input a unique app name');
    return false;
  }

  return true;
};

export const AddApp = ({ handleAddApp }: IAddAppProps) => {
  const [showDialog, setShowDialog] = React.useState(false);

  const [appName, setAppName] = React.useState<string>('');
  const [appDef, setAppDef] = React.useState<string>('');

  const onAppNameChanged = React.useCallback(
    (e: React.SyntheticEvent) => {
      const value = (e.target as HTMLInputElement).value;
      setAppName(value);
    },
    [setAppName]
  );

  const onAppDefChanged = React.useCallback(
    (e: React.SyntheticEvent) => {
      const value = (e.target as HTMLInputElement).value;
      setAppDef(value);
    },
    [setAppDef]
  );

  const addApp = () => {
    if (validateAppDef(appDef) && validateAppName(appName)) {
      // save to local storage
      localStorage.setItem(
        'app.' + appName,
        JSON.stringify({
          content: appName,
          def: JSON.parse(appDef),
        })
      );
      setShowDialog(false);
      setAppName('');
      setAppDef('');
      handleAddApp();
    }
  };

  return (
    <>
      <Dialog
        minWidth={400}
        hidden={!showDialog}
        onDismiss={() => setShowDialog(false)}
        dialogContentProps={{
          title: 'Sideload an application',
        }}
      >
        <>
          <TextField label="Application Name" value={appName} onChange={onAppNameChanged} required />
          <TextField label="App definition" value={appDef} onChange={onAppDefChanged} required multiline rows={10} />
        </>
        <DialogFooter>
          <PrimaryButton onClick={() => addApp()} text="Add" />
          <DefaultButton onClick={() => setShowDialog(false)} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <PrimaryButton text="Sideload an App" onClick={() => setShowDialog(true)} />
    </>
  );
};
