import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  INavLink,
  INavLinkGroup,
  Nav,
  Separator,
  Stack,
  StackItem,
  Toggle,
} from '@fluentui/react';
import {
  AppDefEnum,
  FileOpenPreference,
  FrameContexts,
  HostClientType,
  HubNames,
  MetaOSAuthExtension,
  Themes,
  IAppDefinitionFull
} from '@metaos/hub-sdk';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import { getMockAppDefinition } from '../../mocks/MockAppDefinition';
import MockAuthenticationService from '../../mocks/MockAuthenticationService';
import { getAppDefinitionOverrides } from '../../Utils';
import { IAppDictionary } from '../App';
import { DefaultApps } from '../App';
import { AddApp } from './AddApp';
import { CustomEventDispatcher } from './CustomEventDispatcher';

const linksFromAppDictionary = (appDictionary: IAppDictionary): INavLink[] => {
  const links: INavLink[] = [];

  for (const appKey in appDictionary) {
    let name = appKey;
    if ('name' in appDictionary[appKey]) {
      let definitionFull = appDictionary[appKey] as IAppDefinitionFull;
      name = definitionFull.name;  
    }
    
    links.push({ name: name, url: '/app/' + appKey, key: appKey });
  }

  return links;
};

interface ILeftBarProps {
  handleSideloadApp: () => void;
  sideloadedApps: IAppDictionary;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
  setFileOpenPreference: React.Dispatch<React.SetStateAction<string>>;
  showTestFrame: boolean;
  setShowTestFrame: (visible: boolean) => void;
}
var pjson = require('../../../package.json');
export const LeftBar = ({
  sideloadedApps,
  handleSideloadApp,
  setTheme,
  setFileOpenPreference,
  showTestFrame,
  setShowTestFrame,
}: ILeftBarProps) => {
  const history = useHistory();

  const match = useRouteMatch<{ appName: string }>('/app/:appName');
  const selectedApp = match ? match.params.appName : AppDefEnum.Test;

  const navGroups = [];

  const [defaultAppsLinkGroup] = React.useState<INavLinkGroup>({
    links: linksFromAppDictionary(DefaultApps),
  });
  navGroups.push(defaultAppsLinkGroup);

  if (sideloadedApps && Object.entries(sideloadedApps).length > 0) {
    navGroups.push({
      name: 'Sideloaded',
      links: linksFromAppDictionary(sideloadedApps),
    });
  }

  const fileOpenPreferenceOptions: IDropdownOption[] = [
    { key: 'inline', text: FileOpenPreference.Inline },
    { key: 'desktop', text: FileOpenPreference.Desktop },
    { key: 'web', text: FileOpenPreference.Web },
  ];

  return (
    <Stack className="left" verticalFill>
      <StackItem grow>
        <div data-aa-class="Menu">
          <Nav
            className="Menu"
            onLinkClick={(event, element) => {
              // this is needed, because we're using React Router and want to intercept the click
              event!.preventDefault();
              history.push(element!.url);
            }}
            groups={navGroups}
            selectedKey={selectedApp}
          />
        </div>
      </StackItem>
      <Separator />
      <StackItem>
        <CustomEventDispatcher />
      </StackItem>
      <StackItem align="center">
        <Toggle
          label="Change Theme"
          id="themeToggle"
          offText="default"
          onText="dark"
          onChange={() => {
            setTheme((prevTheme) => {
              return prevTheme === Themes.default ? Themes.dark : Themes.default;
            });
          }}
        />
      </StackItem>
      <StackItem align="center">
        <Toggle
          label="Test Frame"
          id="testFrameToggle"
          offText="hidden"
          onText="visible"
          onChange={() => {
            setShowTestFrame(!showTestFrame);
          }}
        />
      </StackItem>
      <StackItem align="center">
        <Dropdown
          options={fileOpenPreferenceOptions}
          label="File Open Preference"
          id="fileOpenPreferenceToggle"
          onChange={(_, option) => {
            setFileOpenPreference(option?.text as FileOpenPreference);
          }}
        />
      </StackItem>
      <Separator />
      <StackItem align="center">
        <Link to="/capability-settings">
          <DefaultButton text="Toggle Modules" id="toggleModules" />
        </Link>
      </StackItem>
      <Separator />
      <StackItem align="center">
        <DefaultButton
          text="MetaOSAuthExtension"
          id="metaOSAuthExtension"
          onClick={() => {
            const testUrl = 'https://localhost:4000/?customInit=true';
            const appDef = {
              ...getMockAppDefinition(testUrl),
              ...getAppDefinitionOverrides(),
            };
            new MetaOSAuthExtension(
              window,
              {
                url: testUrl,
                width: 800,
                height: 600,
                left: window.screen.availWidth / 2 - 400,
                top: window.screen.availHeight / 2 - 300,
              }, // showing pop-up window in middle of screen
              appDef,
              new MockAuthenticationService(),
              {
                app: {
                  locale: 'en-us',
                  theme: Themes.default,
                  sessionId: 'dummy',
                  userClickTime: Date.now(),
                  host: {
                    name: HubNames.orange,
                    clientType: HostClientType.web,
                    sessionId: 'dummySessionOrange',
                    version: pjson.version,
                  },
                },
                page: {
                  id: '',
                  subPageId: '',
                  frameContext: FrameContexts.authentication,
                },
              },
              undefined
            );
          }}
        />
      </StackItem>
      <Separator />
      <StackItem align="center">
        <AddApp handleAddApp={handleSideloadApp} />
      </StackItem>
    </Stack>
  );
};
