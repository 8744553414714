import {
  ChannelType,
  ITeamsFullTrustJoinedTeamsService,
  ITeamsFullTrustService,
  ITeamsService,
  TeamChannelInfo,
  TeamInstanceParameters,
  UserJoinedTeamsInformation,
} from '@metaos/hub-sdk';

class TeamsService implements ITeamsService {
  public fullTrustService?: ITeamsFullTrustService;

  public withFullTrustService(fullTrustServiceInput?: ITeamsFullTrustService): ITeamsService {
    this.fullTrustService = fullTrustServiceInput;
    return this;
  }
  public getTeamChannels(groupId: string): Promise<TeamChannelInfo[]> {
    const channels = [
      {
        siteUrl: 'siteURL',
        objectId: 'objectId',
        folderRelativeUrl: 'folderRelativeUrl',
        displayName: 'displayname',
        channelType: ChannelType.Regular,
      },
    ];
    alert(`getTeamChannels called with: ${groupId}`);
    return Promise.resolve(channels);
  }
  public refreshSiteUrl(threadId: string): Promise<void> {
    alert(`refreshSiteUrl called with: ${threadId}`);
    return Promise.resolve();
  }
}

class FullTrustService implements ITeamsFullTrustService {
  public joinedTeamsService?: ITeamsFullTrustJoinedTeamsService | undefined;
  public getConfigSetting(key: string): Promise<string> {
    alert(`getConfigSetting called with key: ${key}`);
    return Promise.resolve('testValue');
  }

  public withFullTrustJoinedTeamsService(
    fullTrustJoinedTeamsServiceInput?: ITeamsFullTrustJoinedTeamsService
  ): ITeamsFullTrustService {
    this.joinedTeamsService = fullTrustJoinedTeamsServiceInput;
    return this;
  }
}

class FullTrustJoinedTeamsService implements ITeamsFullTrustJoinedTeamsService {
  public getUserJoinedTeams(teamsInstanceParameters: TeamInstanceParameters): Promise<UserJoinedTeamsInformation> {
    alert(`getUserJoinedTeams called with ${JSON.stringify(teamsInstanceParameters)}`);
    return Promise.resolve({
      userJoinedTeams: [{ teamId: 'testTeamId', teamName: 'testTeamName' }],
    });
  }
}

export const teamsService = new TeamsService();
export const teamsFullTrustService = new FullTrustService();
export const teamsFullTrustJoinedTeamsService = new FullTrustJoinedTeamsService();

export const getTeamsService = (capabilities: any): ITeamsService | undefined => {
  if (capabilities.teams) {
    if (capabilities.teamsFullTrust) {
      teamsService.withFullTrustService(teamsFullTrustService);
      if (capabilities.teamsFullTrustJoinedTeams) {
        teamsFullTrustService.withFullTrustJoinedTeamsService(teamsFullTrustJoinedTeamsService);
      } else {
        teamsFullTrustService.withFullTrustJoinedTeamsService(undefined);
      }
    } else {
      teamsService.withFullTrustService(undefined);
    }
    return teamsService;
  } else {
    return undefined;
  }
};
