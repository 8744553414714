import {
  AppButtonServiceEventMap,
  BackStackServiceEventMap,
  Config,
  ConfigServiceEventMap,
  EventService,
  FrameInfo,
  IAppButtonService,
  IBackStackService,
  IConfigService,
  IPageService,
  IPagesFullTrustService,
  IPageTabsService,
  LinkParameters,
  NavigateToAppParams,
  PageServiceEventMap,
  TabInformation,
  TabInstance,
  TabInstanceParameters,
} from '@metaos/hub-sdk';

const tabInfo: TabInformation = {
  teamTabs: [
    {
      tabName: 'dummy1',
      channelId: '1',
    },
    {
      tabName: 'dummy2',
      channelId: '1',
    },
  ],
};
class PageService extends EventService<PageServiceEventMap> implements IPageService {
  public appLinkDomains: string[] = ['local.teams.office.com'];
  public appLinkRootPath = 'l/entity';
  public appButtonService?: IAppButtonService;
  public tabsService?: IPageTabsService;
  public configService?: IConfigService;
  public backStackService?: IBackStackService;
  public fullTrustService?: IPagesFullTrustService;

  private appName?: string;
  private entityId?: string;
  private setDeepLinkToShare?: (url: string) => void;

  public setDeepLinkSharingParams = (appName: string, entityId: string, setDeepLinkToShare: (url: string) => void) => {
    this.appName = appName;
    this.entityId = entityId;
    this.setDeepLinkToShare = setDeepLinkToShare;
  };

  public getConfig?: () => Promise<Config> = undefined;

  public returnFocus(navigateForward?: boolean): Promise<void> {
    alert(`returnFocus called with ${JSON.stringify(navigateForward)}`);
    return Promise.resolve();
  }

  public setCurrentFrame(frameInfo: FrameInfo): Promise<void> {
    alert(`setCurrentFrame called with ${JSON.stringify(frameInfo)}`);
    return Promise.resolve();
  }

  public navigateToApp(params: NavigateToAppParams): Promise<void> {
    alert(`navigateToApp called with ${JSON.stringify(params)}`);
    return Promise.resolve();
  }

  public shareDeepLink(params: LinkParameters): Promise<void> {
    alert(`shareDeepLink called with ${JSON.stringify(params)}`);
    if (this.appName && this.entityId && this.setDeepLinkToShare) {
      const urlToShare = getDeepLink(this.appName, this.entityId, params.subEntityId);
      this.setDeepLinkToShare(urlToShare);
    }
    return Promise.resolve();
  }

  public withAppButtonService(appButtonServiceInput?: IAppButtonService): IPageService {
    this.appButtonService = appButtonServiceInput;
    return this;
  }

  public withTabsService(tabsServiceInput?: IPageTabsService): IPageService {
    this.tabsService = tabsServiceInput;
    return this;
  }

  public withConfigService(configServiceInput?: IConfigService): IPageService {
    this.configService = configServiceInput;
    if (configServiceInput) {
      this.getConfig = (): Promise<Config> => {
        alert('getConfig called');
        return Promise.resolve(tempConfig);
      };
    } else {
      this.getConfig = undefined;
    }
    return this;
  }

  public withBackStackService(backStackServiceInput?: IBackStackService): IPageService {
    this.backStackService = backStackServiceInput;
    return this;
  }

  public withFullTrustService(fullTrustServiceInput?: IPagesFullTrustService): IPageService {
    this.fullTrustService = fullTrustServiceInput;
    return this;
  }
}

const getDeepLink = (appName: string, entityId: string, subEntityId: string): string => {
  const url = new URL(window.location.toString());
  url.pathname = `/app/${appName}/${entityId}/${subEntityId}`;
  return url.toString();
};

class PageAppButtonService extends EventService<AppButtonServiceEventMap> implements IAppButtonService {}

class PageTabsService implements IPageTabsService {
  public navigateToTab(tabInstance: TabInstance): Promise<boolean> {
    alert(`navigateToTab called with ${JSON.stringify(tabInstance)}`);
    return Promise.resolve(true);
  }
  public getTabInstances(tabInstanceParameters?: TabInstanceParameters): Promise<TabInformation> {
    alert(`getTabInstances called with ${JSON.stringify(tabInstanceParameters)}`);
    return Promise.resolve(tabInfo);
  }
  public getMruTabInstances(tabInstanceParameters?: TabInstanceParameters): Promise<TabInformation> {
    alert(`getMruTabInstances called with ${JSON.stringify(tabInstanceParameters)}`);
    return Promise.resolve(tabInfo);
  }
}

const tempConfig: Config = {
  contentUrl: 'https://www.bing.com',
  entityId: '1',
  suggestedDisplayName: 'MetaOSApp',
};

class ConfigService extends EventService<ConfigServiceEventMap> implements IConfigService {
  public setConfig(settings: Config): Promise<boolean> {
    alert(`setConfig called with ${JSON.stringify(settings)}`);
    return Promise.resolve(true);
  }
  public setValidityState(validityState: boolean): Promise<void> {
    alert(`setValidityState called with ${JSON.stringify(validityState)}`);
    return Promise.resolve();
  }
  public onSaveSuccess(): Promise<void> {
    alert('onSaveSuccess called');
    return Promise.resolve();
  }
  public onSaveFailure(reason?: string): Promise<void> {
    alert('onSaveFailure called with ' + reason);
    return Promise.resolve();
  }
  public onRemoveSuccess(): Promise<void> {
    alert('onRemoveSuccess called');
    return Promise.resolve();
  }
  public onRemoveFailure(reason?: string): Promise<void> {
    alert('onRemoveFailure called with ' + reason);
    return Promise.resolve();
  }
}

class BackStackService extends EventService<BackStackServiceEventMap> implements IBackStackService {
  public navigateBack(): Promise<boolean> {
    alert('navigateBack called');
    return Promise.resolve(true);
  }
}
class FullTrustService implements IPagesFullTrustService {
  public enterFullscreen(): Promise<void> {
    alert('enterFullscreen called');
    return Promise.resolve();
  }
  public exitFullscreen(): Promise<void> {
    alert('exitFullscreen called');
    return Promise.resolve();
  }
}

export const pageService = new PageService();
export const pageAppButtonService = new PageAppButtonService();
export const pageTabsService = new PageTabsService();
export const pageConfigService = new ConfigService();
export const pageBackStackService = new BackStackService();
export const pageFullTrustService = new FullTrustService();

export const getPageService = (
  appName: string,
  entityId: string,
  setDeepLinkToShare: (urlToShare: string) => void,
  capabilities: any
): IPageService | undefined => {
  pageService.setDeepLinkSharingParams(appName, entityId, setDeepLinkToShare);
  if (capabilities.page) {
    if (capabilities.pageAppButton) {
      pageService.withAppButtonService(pageAppButtonService);
    } else {
      pageService.withAppButtonService(undefined);
    }

    if (capabilities.pageTabs) {
      pageService.withTabsService(pageTabsService);
    } else {
      pageService.withTabsService(undefined);
    }

    if (capabilities.pageConfig) {
      pageService.withConfigService(pageConfigService);
    } else {
      pageService.withConfigService(undefined);
    }

    if (capabilities.pageBackStack) {
      pageService.withBackStackService(pageBackStackService);
    } else {
      pageService.withBackStackService(undefined);
    }
    if (capabilities.pageFullTrust) {
      pageService.withFullTrustService(pageFullTrustService);
    } else {
      pageService.withFullTrustService(undefined);
    }

    return pageService;
  } else {
    return undefined;
  }
};
