import { FileOpenPreference, getAppDefinition, IAppDefinition, IAuthenticationService, Themes } from '@metaos/hub-sdk';
import React from 'react';

import { ICapabilitiesState } from './App';

export interface OrangeContextProps {
  authService?: IAuthenticationService;
  capabilities: ICapabilitiesState;
  sessionId: string;
  theme: Themes;
  fileOpenPreference: FileOpenPreference;
}

export const OrangeContext = React.createContext<OrangeContextProps>({
  capabilities: {},
  theme: Themes.default,
  fileOpenPreference: FileOpenPreference.Web,
  sessionId: '',
});

export interface AppContainerRouteContextProps {
  appDefinition: IAppDefinition;
}

export const AppContainerRouteContext = React.createContext<AppContainerRouteContextProps>({
  appDefinition: getAppDefinition(''),
});
