import {
  ControlCommand,
  EventService,
  IRemoteCameraService,
  Participant,
  RemoteCameraServiceEventMap,
} from '@metaos/hub-sdk';

const participants: Participant[] = [
  {
    id: 'sampleParticiapntID',
    displayName: 'SampleDispalayName',
    active: true,
  },
];

class RemoteCameraService extends EventService<RemoteCameraServiceEventMap> implements IRemoteCameraService {
  public getCapableParticipants(): Promise<Participant[]> {
    alert(`getCapableParticipants is called`);
    return Promise.resolve(participants);
  }
  public requestControl(participant: Participant): Promise<boolean> {
    alert(`requestControl is called with ${JSON.stringify(participant)}`);
    return Promise.resolve(true);
  }
  public sendControlCommand(Reset: ControlCommand): Promise<void> {
    alert(`sendControlCommand is called with ${JSON.stringify(Reset)}`);
    return Promise.resolve();
  }
  public terminateSession(): Promise<void> {
    alert(`terminateSession is called`);
    return Promise.resolve();
  }
}
export const remoteCameraService = new RemoteCameraService();
