import { ITelemetryScenario, ITelemetryService, TelemetryPhaseName, TelemetryScenarioName } from '@metaos/hub-sdk';

export class TelemetryService implements ITelemetryService {
  createScenario(name: TelemetryScenarioName, appId?: string, timestamp?: number): ITelemetryScenario {
    return new TelemetryScenario(name, appId, timestamp);
  }
}

class TelemetryScenario implements ITelemetryScenario {
  private startTime: number;
  private lastMark: number;
  private phases: Array<{ name: string; duration: number }> = [];
  private scenarioStartTime: string;

  constructor(private name: TelemetryScenarioName, private appId?: string, clientTimestamp: number = Date.now()) {
    this.startTime = clientTimestamp;
    this.lastMark = clientTimestamp;
    this.scenarioStartTime = this.name + Math.random().toString();
    window.performance.mark(this.scenarioStartTime);
  }

  markPhase(name: TelemetryPhaseName): void {
    const now = Date.now();
    const duration = now - this.lastMark;
    this.lastMark = now;
    this.phases.push({ name: name, duration: duration });
  }

  stop(): void {
    markPerfTimerToWindow(this.name, this.scenarioStartTime, 'Success');
    console.info('MetaOS Hub Telemetry Scenario: ', {
      name: this.name,
      appId: this.appId,
      result: 'success',
      startTime: this.startTime,
      endTime: Date.now(),
      durationInMs: Date.now() - this.startTime,
      phases: this.phases,
    });
  }

  fail(error?: string): void {
    const now = Date.now();
    console.error('MetaOS Hub Telemetry Scenario: ', {
      name: this.name,
      appId: this.appId,
      result: 'failure',
      startTime: this.startTime,
      endTime: now,
      durationInMs: now - this.startTime,
      error,
      phases: this.phases,
    });
    markPerfTimerToWindow(this.name, this.scenarioStartTime, 'Failure');
  }
}

function markPerfTimerToWindow(markName: string, startTime: string, result: string) {
  // writing perf marker to window object, for Perf tests.
  let scenarioEndTime = markName + 'endTime';
  window.performance.mark(scenarioEndTime);
  window.performance.measure(markName + result, startTime, scenarioEndTime);
  window.performance.clearMarks(scenarioEndTime);
  window.performance.clearMarks(startTime);
}
