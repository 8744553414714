import { IAppDefinitionFull } from '@metaos/hub-sdk';
import { IM365AppDefinition } from './m365AppDefinition';

export interface M365AppDefinitions {
  appDefinitions: Array<IM365AppDefinition>;
}

export class MetaOSServiceClient {

  constructor() {
  }

  public getEntitledApps = async (token: string ): Promise<Array<IM365AppDefinition> | undefined> => {

    // GACALD: Switch Urls for local debugging
    //const serviceUrl: string  = "http://localhost:7071/api/GetEntitlements";
    const serviceUrl: string  = "https://neonmetaosproxy.azurewebsites.net/api/GetEntitlements";

    let request = new Request(serviceUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Authorization': 'Bearer ' + token,
        },
        credentials: 'include'
    });

    return fetch(request)
      .then((response: any) => {

        let appDefinitions = response.json() as Array<IM365AppDefinition>;
        return appDefinitions;
      })
      .catch((error: any) => {
        console.error("Error calling Teams MTS: " + error);
        return undefined;
      });
  }

  public getEntitledApp = async (token: string, appId: string): Promise<IM365AppDefinition | undefined> => {
    let apps = await this.getEntitledApps(token);
    if (apps == undefined) {
      return apps;
    }

    let findId = appId.toUpperCase();

    for (let index = 0; index < apps.length; index++) {
      const element = apps[index];
      if (element.id.toUpperCase() == findId) {
        return element;
      }
    }

    return undefined;
  }

  public convertToHubSDKDefinition = (inDef: IM365AppDefinition) : IAppDefinitionFull => {
    let outDef: IAppDefinitionFull = {
      accentColor: inDef.accentColor,
      developerName: inDef.developerName,
      developerUrl: inDef.developerUrl,
      largeImageUrl: inDef.largeImageUrl,
      longDescription: inDef.longDescription,
      isFullScreen: inDef.isFullScreen,
      name: inDef.name,
      privacyUrl: inDef.privacyUrl,
      screenshotUrls: inDef.screenshotUrls,
      shortDescription: inDef.shortDescription,
      showLoadingIndicator: inDef.showLoadingIndicator ?? false,
      smallImageUrl: inDef.smallImageUrl,
      termsOfUseUrl: inDef.termsOfUseUrl,
      videoUrl: inDef.videoUrl,
      appId: inDef.id,
      version: inDef.version,
      galleryTabs: inDef.galleryTabs,
      isFullTrustApp: inDef.isFullTrustApp,
      staticTabs: inDef.staticTabs,
      tenantId: inDef.tenantId,
      validDomains: inDef.validDomains,
      webApplicationInfo: inDef.webApplicationInfo
    };

    return outDef;
  }
}

export default MetaOSServiceClient;
