import { ComposeMailParams, IMailService, OpenMailItemParams } from '@metaos/hub-sdk';

class MailService implements IMailService {
  public openMailItem = (openMailItemParams: OpenMailItemParams) => {
    alert(`openMailItem called with itemId: ${openMailItemParams.itemId}`);
    return Promise.resolve();
  };
  public composeMail = (params: ComposeMailParams) => {
    alert(`composeMail called with ${JSON.stringify(params)}`);
    return Promise.resolve();
  };
}
export const mailService = new MailService();
