import { Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import React from 'react';

export interface ShareDeepLinkDialogPorps {
  urlToShare?: string;
  onDismiss: () => void;
}

export const ShareDeepLinkDialog: React.FC<ShareDeepLinkDialogPorps> = (props: ShareDeepLinkDialogPorps) => {
  const { urlToShare, onDismiss } = props;

  return (
    <Dialog
      hidden={!urlToShare}
      dialogContentProps={{
        title: 'Share Deep Link',
        closeButtonAriaLabel: 'Close',
        type: DialogType.normal,
        subText: urlToShare,
      }}
    >
      <DialogFooter>
        <PrimaryButton onClick={() => onDismiss()} text="Close" />
      </DialogFooter>
    </Dialog>
  );
};
