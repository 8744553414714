import { ComposeMeetingParams, ICalendarService, OpenCalendarItemParams } from '@metaos/hub-sdk';

class CalendarService implements ICalendarService {
  public openCalendarItem = (openCalendarItemParams: OpenCalendarItemParams) => {
    alert(`openCalendarItem called with itemId: ${openCalendarItemParams.itemId}`);
    return Promise.resolve();
  };
  public composeMeeting = (params: ComposeMeetingParams) => {
    alert(`composeMeeting called with ${JSON.stringify(params)}`);
    return Promise.resolve();
  };
}
export const calendarService = new CalendarService();
