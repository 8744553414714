import { Stack, StackItem } from '@fluentui/react';
import { IAuthenticationService } from '@metaos/hub-sdk';
import * as React from 'react';

import { IAuthenticationServiceExtended } from '../../services/AuthenticationService';
import { SignIn } from './SignIn';
import { SignOut } from './SignOut';

interface IHeaderProps {
  authService: IAuthenticationServiceExtended | undefined;
  setAuthService: (authService: IAuthenticationService) => void;
}
export const Header = ({ authService, setAuthService }: IHeaderProps) => {
  const [username, setUsername] = React.useState('');

  React.useEffect(() => {
    authService &&
      authService.getUser().then((p) => {
        if (p) {
          setUsername(p.profile.upn);
        }
      });
  }, [authService]);

  return (
    <Stack
      className="Header"
      style={{ backgroundColor: '#FF9D5C', height: '60px' }}
      horizontalAlign="end"
      horizontal
      tokens={{ childrenGap: 5, padding: 10 }}
    >
      <StackItem grow align="center">
        <h1>Carrot Web</h1>
      </StackItem>
      <StackItem align="center">
        <h3>{username || 'User Not Signed In'}</h3>
      </StackItem>
      <StackItem align="center">
        {username ? <SignOut authService={authService} setAuthService={setAuthService} /> : <SignIn setAuthService={setAuthService} />}
      </StackItem>
    </Stack>
  );
};
