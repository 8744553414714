import {
  Dialog,
  DialogContentType,
  DialogServiceEventMap,
  DialogSize,
  EventService,
  IDialogService,
  IDialogUpdateService,
} from '@metaos/hub-sdk';

import { TaskModuleDialogProps, taskModuleDialogServiceInstance } from '../components/Main/TaskModuleDialog';

class DialogService extends EventService<DialogServiceEventMap> implements IDialogService {
  public updateService?: IDialogUpdateService;
  constructor(private appName: string, private setTaskModuleDialogProps: (newProps: TaskModuleDialogProps) => void) {
    super();
  }

  messageForParent(_: unknown): Promise<void> {
    return Promise.reject();
  }

  messageForChild(message: unknown): Promise<void> {
    if (taskModuleDialogServiceInstance) {
      taskModuleDialogServiceInstance.dispatchEvent('onMessageForChild', { data: message });
    }
    return Promise.resolve();
  }

  public open = (dialog: Dialog): Promise<string> => {
    if (dialog.type !== DialogContentType.Url) {
      return Promise.reject('Not supported?');
    }

    let submit: (result: string) => void;
    let dismiss: (err: string) => void;

    const returnPromise = new Promise<string>((res, rej) => {
      submit = (result: string) => res(result);
      dismiss = (err: string) => rej(err);
    });

    this.setTaskModuleDialogProps({
      appName: this.appName,
      show: true,
      dialogInfo: dialog,
      onDismiss: () => {
        dismiss && dismiss('User cancelled/closed the task module.');
        this.setTaskModuleDialogProps({ appName: this.appName, show: false });
      },
      onSubmit: (result: string) => {
        submit && submit(result);
        this.setTaskModuleDialogProps({ appName: this.appName, show: false });
      },

      onMessageForParent: (message: unknown) => {
        this.dispatchEvent('onMessageForParent', { data: message });
        return Promise.resolve();
      },
    });

    alert(`dialog.open() called with ${JSON.stringify(dialog)}`);
    return returnPromise;
  };

  public withUpdateService(updateServiceInput?: IDialogUpdateService): IDialogService {
    this.updateService = updateServiceInput;
    return this;
  }

  public submit(result: string): Promise<void> {
    alert(`dialog.submit() called with ${JSON.stringify(result)}`);

    return Promise.resolve();
    // no rejection if not running in TaskModule mode to begin with!
  }
}

class DialogUpdateService implements IDialogUpdateService {
  resize(size: DialogSize): Promise<void> {
    alert(`dialog.resize() called with ${JSON.stringify(size)}`);

    return Promise.resolve();
  }
}

export const dialogUpdateService = new DialogUpdateService();

export const getDialogService = (
  appId: string,
  setTaskModuleDialogProps: (newProps: TaskModuleDialogProps) => void,
  capabilities: any
) => {
  if (capabilities.dialog) {
    const dialogService = new DialogService(appId, setTaskModuleDialogProps);
    if (capabilities.dialogUpdate) {
      dialogService.withUpdateService(dialogUpdateService);
    } else {
      dialogService.withUpdateService(undefined);
    }
    return dialogService;
  } else {
    return undefined;
  }
};
