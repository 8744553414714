import { IAppDefinition } from '@metaos/hub-sdk';

export const getMockAppDefinition = (serverUrl: string): IAppDefinition => {
  return {
    galleryTabs: [
      {
        configurationUrl: serverUrl,
      },
    ],
    appId: 'com.example.metaostestapp.test',
    staticTabs: [
      {
        contentUrl: serverUrl,
        entityId: 'serverUrl',
        name: 'Server Url',
      },
    ],
    validDomains: [new URL(serverUrl).host, '*tab.azurewebsites.net'],
    showLoadingIndicator: true,
    isFullTrustApp: true,
    webApplicationInfo: {
      id: '00000005-0000-0ff1-ce00-000000000000',
      resource: serverUrl,
    },
    version: '0.0.0',
  };
};
